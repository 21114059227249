import React, { useMemo } from 'react';

import {
  Admin,
  Resource,
  CustomRoutes,
  fetchUtils,
} from 'react-admin';
import { Route } from 'react-router';
import './AdminContainer.scss';
import { useAuthContext, usePermissions } from 'providers/AuthProvider';
import jsonServerProvider from 'ra-data-json-server';

import { getMessageBuilderApiUrl } from 'utils/api';

import DomainsShow from 'components/resources/Domains/Show';
import DomainsList from 'components/resources/Domains/List';
import DomainsEdit from 'components/resources/Domains/Edit';
import DomainsCreate from 'components/resources/Domains/Create';

import FormsShow from 'components/resources/Forms/Show';
import FormsList from 'components/resources/Forms/List';
import FormsEdit from 'components/resources/Forms/Edit';
import FormsCreate from 'components/resources/Forms/Create';

import MessagesShow from 'components/resources/Messages/Show';
import MessagesList from 'components/resources/Messages/List';
import MessagesEdit from 'components/resources/Messages/Edit';
import MessagesCreate from 'components/resources/Messages/Create';

import { Alert } from '@mui/material';

const httpClient = (url, options = {}) => {
  if (!options.headers) {
    // eslint-disable-next-line no-param-reassign
    options.headers = new window.Headers({ Accept: 'application/json' });
  }
  options.headers.set('Authorization', `Bearer ${localStorage.getItem('token')}`);
  return fetchUtils.fetchJson(url, options);
};

const dataProvider = jsonServerProvider(getMessageBuilderApiUrl(), httpClient);

const AdminContainer = () => {
  const authCtx = useAuthContext();

  const permissions = usePermissions();

  if (!permissions.length) {
    return (
      <Alert>Forbidden</Alert>
    );
  }

  const AvailableComponents = useMemo(() => [
    { component: MessagesList, permissions: ['view'], key: 'MessagesList' },
    { component: MessagesShow, permissions: ['view'], key: 'MessagesShow' },
    { component: MessagesEdit, permissions: ['view', 'edit'], key: 'MessagesEdit' },
    { component: MessagesCreate, permissions: ['view', 'edit'], key: 'MessagesCreate' },

    { component: DomainsList, permissions: ['view'], key: 'DomainsList' },
    { component: DomainsShow, permissions: ['view'], key: 'DomainsShow' },
    { component: DomainsEdit, permissions: ['view', 'edit'], key: 'DomainsEdit' },
    { component: DomainsCreate, permissions: ['view', 'edit'], key: 'DomainsCreate' },

    { component: FormsList, permissions: ['view'], key: 'FormsList' },
    { component: FormsShow, permissions: ['view'], key: 'FormsShow' },
    { component: FormsEdit, permissions: ['view', 'edit'], key: 'FormsEdit' },
    { component: FormsCreate, permissions: ['view', 'edit'], key: 'FormsCreate' },
  ].reduce((acc, item) => {
    const allowed = item.permissions.every((permissionItem) => permissions.includes(permissionItem));
    if (!allowed) {
      return acc;
    }
    return {
      ...acc,
      [item.key]: item.component,
    };
  }, {}), [permissions]);

  return (
    <Admin
      basename="/app"
      authProvider={authCtx.raAuthProvider}
      dataProvider={dataProvider}
    >
      <Resource
        name="messages"
        list={AvailableComponents.MessagesList}
        show={AvailableComponents.MessagesShow}
        edit={AvailableComponents.MessagesEdit}
        create={AvailableComponents.MessagesCreate}
      />
      <Resource
        name="domains"
        list={AvailableComponents.DomainsList}
        show={AvailableComponents.DomainsShow}
        edit={AvailableComponents.DomainsEdit}
        create={AvailableComponents.DomainsCreate}
      />
      <Resource
        name="forms"
        list={AvailableComponents.FormsList}
        show={AvailableComponents.FormsShow}
        edit={AvailableComponents.FormsEdit}
        create={AvailableComponents.FormsCreate}
      />
      <CustomRoutes>
        <Route path="/my-profile" element={<div>My Profile</div>} />
      </CustomRoutes>
    </Admin>
  );
};

export default AdminContainer;
