import React from 'react';
import { Stack } from '@mui/material';

import {
  FormTab, TabbedForm, TextInput,
} from 'react-admin';

const EditFormInner = () => (
  <TabbedForm>
    <FormTab label="General Settings">
      <Stack spacing={2} direction="column" width="100%">
        <Stack spacing={2} direction="row">
          <TextInput source="name" />
        </Stack>
        <Stack spacing={2} direction="row" width="100%">
          <TextInput source="slackWebhookUrl" fullWidth />
          <TextInput multiline source="script" fullWidth />
        </Stack>
      </Stack>
    </FormTab>
  </TabbedForm>
);

export default EditFormInner;
