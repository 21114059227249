import React from 'react';
import {
  ArrayField,
  BooleanField,
  Datagrid,
  ReferenceField,
  Show, SimpleShowLayout, TextField,
} from 'react-admin';

import { Card, CardHeader, Stack } from '@mui/material';
import DateFieldUnixtime from 'components/fields/DateFieldUnixtime/DateFieldUnixtime';

const FormShow = () => (
  <Show>
    <Stack p={2} spacing={2}>
      <Card raised>
        <CardHeader>
          Form
        </CardHeader>
        <Stack direction="row" spacing={2}>
          <SimpleShowLayout>
            <DateFieldUnixtime source="timestampCreated" label="Created at" showTime />
          </SimpleShowLayout>
          <SimpleShowLayout>
            <TextField source="name" />
          </SimpleShowLayout>
          <SimpleShowLayout>
            <ReferenceField source="domain" reference="domains">
              <TextField source="name" />
            </ReferenceField>
          </SimpleShowLayout>
        </Stack>
      </Card>
      <Stack direction="row" spacing={2} p={2}>
        <Stack spacing={2} maxWidth="250px">
          <h3>Form Settings</h3>
          <SimpleShowLayout>
            <BooleanField source="ignoreUnlistedFields" />
            <TextField source="formSelector" />
            <TextField source="submitSelector" />
            <TextField source="successMessageSelector" />
            <TextField source="errorMessageSelector" />
            <ArrayField source="fields">
              <Datagrid>
                <TextField source="fieldOriginalName" />
                <TextField source="fieldSubmitName" />
              </Datagrid>
            </ArrayField>
          </SimpleShowLayout>
        </Stack>
      </Stack>
    </Stack>
  </Show>
);

export default FormShow;
