import React from 'react';
import { Stack } from '@mui/material';

import {
  ArrayInput,
  BooleanInput,
  FormTab, ReferenceInput, SelectInput, SimpleFormIterator, TabbedForm, TextInput,
} from 'react-admin';

const EditFormInner = () => (
  <TabbedForm>
    <FormTab label="General Settings">
      <Stack spacing={2} direction="column">
        <ReferenceInput source="domain" reference="domains">
          <SelectInput optionText="name" />
        </ReferenceInput>
        <TextInput source="name" />
        <BooleanInput source="ignoreUnlistedFields" />
        <TextInput source="formSelector" />
        <TextInput source="submitSelector" />
        <TextInput source="successMessageSelector" />
        <TextInput source="errorMessageSelector" />
        <ArrayInput source="fields">
          <SimpleFormIterator>
            <TextInput source="fieldOriginalName" />
            <TextInput source="fieldSubmitName" />
          </SimpleFormIterator>
        </ArrayInput>
      </Stack>
    </FormTab>
  </TabbedForm>
);

export default EditFormInner;
