import React from 'react';
import {
  Datagrid, List, ShowButton, TextField, EditButton,
} from 'react-admin';

import DateFieldUnixtime from 'components/fields/DateFieldUnixtime/DateFieldUnixtime';

const DomainsList = () => (
  <List>
    <Datagrid>
      <DateFieldUnixtime source="timestampCreated" label="Created at" showTime />
      <TextField source="name" />
      <ShowButton />
      <EditButton />
    </Datagrid>
  </List>
);

export default DomainsList;
