import React from 'react';
import { Stack } from '@mui/material';

import {
  FormTab, TabbedForm, TextInput, SimpleFormIterator, BooleanInput, ArrayInput,
} from 'react-admin';

const EditFormInner = () => (
  <TabbedForm>
    <FormTab label="General Settings">
      <Stack spacing={2} direction="column">
        <Stack spacing={2} direction="row" alignItems="center">
          <TextInput source="domain" />
          <TextInput source="formName" />
          <BooleanInput source="answered" />
        </Stack>
        <Stack spacing={2} direction="row" alignItems="center">
          <TextInput source="firstName" />
          <TextInput source="lastName" />
          <TextInput source="email" />
        </Stack>
        <Stack spacing={2} direction="row" alignItems="center">
          <ArrayInput source="fields">
            <SimpleFormIterator>
              <TextInput source="name" />
              <TextInput source="value" />
            </SimpleFormIterator>
          </ArrayInput>
        </Stack>
      </Stack>
    </FormTab>
  </TabbedForm>
);

export default EditFormInner;
