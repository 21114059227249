import React from 'react';
import {
  ArrayField,
  BooleanField,
  Datagrid,
  EmailField, Show, SimpleShowLayout, TextField,
} from 'react-admin';

import { Card, CardHeader, Stack } from '@mui/material';
import DateFieldUnixtime from 'components/fields/DateFieldUnixtime/DateFieldUnixtime';

const MessageShow = () => (
  <Show>
    <Stack p={2} spacing={2}>
      <Card raised>
        <CardHeader>
          Message
        </CardHeader>
        <Stack direction="row" spacing={2}>
          <SimpleShowLayout>
            <DateFieldUnixtime source="timestampCreated" label="Created at" showTime />
          </SimpleShowLayout>
          <SimpleShowLayout>
            <TextField source="domain" />
          </SimpleShowLayout>
          <SimpleShowLayout>
            <TextField source="formName" />
          </SimpleShowLayout>
        </Stack>
      </Card>
      <Stack direction="row" spacing={2} p={2}>
        <Stack spacing={2} maxWidth="250px">
          <h3>General</h3>
          <SimpleShowLayout>
            <TextField source="firstName" />
            <TextField source="lastName" />
            <EmailField source="email" />
            <BooleanField source="answered" />
            <ArrayField source="fields">
              <Datagrid>
                <TextField source="name" />
                <TextField source="value" />
              </Datagrid>
            </ArrayField>
          </SimpleShowLayout>
        </Stack>
      </Stack>
    </Stack>
  </Show>
);

export default MessageShow;
