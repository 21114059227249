import React from 'react';
import {
  TextInput, DateInput, Datagrid, List, ShowButton, TextField, EditButton, EmailField, SelectInput, ReferenceInput,
  BooleanField,
} from 'react-admin';

import DateFieldUnixtime from 'components/fields/DateFieldUnixtime/DateFieldUnixtime';

const messageFilters = [
  // <SelectInput label="Domain" source="domain" alwaysOn resettable choices={[]} />,
  <ReferenceInput label="Domain" source="domain" reference="domains" alwaysOn resettable>
    <SelectInput optionText="name" />
  </ReferenceInput>,
  <TextInput label="Search" source="q" alwaysOn resettable />,
  <TextInput label="Email" source="email" alwaysOn resettable />,
  <DateInput label="Date from" source="dateFrom" resettable />,
  <DateInput label="Date to" source="dateTo" resettable />,
  <TextInput label="Form Name" source="formName" resettable />,
];

const MessagesList = () => (
  <List filters={messageFilters}>
    <Datagrid>
      <DateFieldUnixtime source="timestampCreated" label="Created at" showTime />
      <TextField source="domain" />
      <TextField source="formName" />
      <TextField source="firstName" />
      <TextField source="lastName" />
      <EmailField source="email" />
      <BooleanField source="answered" />
      <ShowButton />
      <EditButton />
    </Datagrid>
  </List>
);

export default MessagesList;
