import React from 'react';
import {
  Show, SimpleShowLayout, TextField, UrlField,
} from 'react-admin';

import { Card, CardHeader, Stack } from '@mui/material';
import DateFieldUnixtime from 'components/fields/DateFieldUnixtime/DateFieldUnixtime';

const DomainShow = () => (
  <Show>
    <Stack p={2} spacing={2}>
      <Card raised>
        <CardHeader>
          Domain
        </CardHeader>
        <Stack direction="row" spacing={2}>
          <SimpleShowLayout>
            <DateFieldUnixtime source="timestampCreated" label="Created at" showTime />
          </SimpleShowLayout>
          <SimpleShowLayout>
            <TextField source="name" />
          </SimpleShowLayout>
        </Stack>
      </Card>
      <Stack>
        <SimpleShowLayout>
          <UrlField source="slackWebhookUrl" />
          <TextField source="script" />
        </SimpleShowLayout>
      </Stack>
    </Stack>
  </Show>
);

export default DomainShow;
